/* 按钮颜色 */
/* 绿色 */
.ec-button-green {
  background-color: #5cb85c;
  border-color: #5cb85c;
  color: white;
}
.ec-button-green:hover {
  background-color: #67c267;
  border-color: #67c267;
  color: white;
}
.ec-button-green:active {
  background-color: #67c267;
  border-color: #67c267;
  color: white;
}
.ec-button-green:focus {
  background-color: #67c267;
  border-color: #67c267;
  color: white;
}

/* 橙色 */
.ec-button-orange {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
  color: white;
}
.ec-button-orange:hover {
  background-color: #f1b867;
  border-color: #f1b867;
  color: white;
}
.ec-button-orange:active {
  background-color: #f1b867;
  border-color: #f1b867;
  color: white;
}
.ec-button-orange:focus {
  background-color: #f1b867;
  border-color: #f1b867;
  color: white;
}

/* 浅蓝色 */
.ec-button-lightBlue {
  background-color: #5bc0de;
  border-color: #5bc0de;
  color: white;
}
.ec-button-lightBlue:hover {
  background-color: #6fc7e2;
  border-color: #6fc7e2;
  color: white;
}
.ec-button-lightBlue:active {
  background-color: #6fc7e2;
  border-color: #6fc7e2;
  color: white;
}
.ec-button-lightBlue:focus {
  background-color: #6fc7e2;
  border-color: #6fc7e2;
  color: white;
}
/* 灰色 */
.ec-button-grey {
  background-color: grey;
  border-color: grey;
  color: white;
}
.ec-button-grey:hover {
  background-color: rgb(161, 159, 159);
  border-color: rgb(161, 159, 159);
  color: white;
}
.ec-button-grey:active {
  background-color: rgb(161, 159, 159);
  border-color: rgb(161, 159, 159);
  color: white;
}
.ec-button-grey:focus {
  background-color: rgb(161, 159, 159);
  border-color: rgb(161, 159, 159);
  color: white;
}

/* 文字颜色 */
/* 红色 */
.ec-text-color-red {
  color: #cc0000;
}
.ec-text-color-green {
  color: #008000;
}
.ec-text-color-orange {
  color: #f0a339;
}
.ec-text-color-grey {
  color: grey;
}
.ec-text-color-blue{
  color:blue;
}
