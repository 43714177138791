.project-manage-row {
    margin-bottom: 10px;
}
.ant-form-item-label label {
    width: 80px;
}
.table .ant-table {
    line-height: 0.5;
}
.studentResume-award > div {
    width: 85%;
    align-self: center;
}
.studentResume-award {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.operate-button{
    border-radius: 6px !important;
    height: 35px !important;
    margin-left:3px;
    margin-right:3px;
}
.addform-button{
    border-radius: 6px !important;
    height: 35px !important;
    margin-left:3px;
    margin-right:3px;
    align-self: center;
}