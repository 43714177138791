.project-manage-row {
    margin-bottom: 10px;
  }
  .ant-form-item-label label {
    width: 80px;
  }
  .table .ant-table {
    line-height: 0.5;
  }
  .studentResume-award > div {
    width: 85%;
    align-self: center;
  }
  .studentResume-award {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
  
  .operate-button{
    border-radius: 0px !important;
    height: 24px !important;
  }
  .addform-button{
    height: 22px !important;
    margin-left:3px;
    margin-right:3px;
    align-self: center;
  }
  
  .ant-upload-select-picture-card i {
    font-size: 28px;
    color: #999;
  }
  
  .ant-upload-select-picture-card .ant-upload-text {
    font-size: 12px;
    color: #666;
    width: 100px;
  }
  
  .ant-upload.ant-upload-select-picture-card > .ant-upload {
    display: inline !important;;
  }
  
  .ant-upload span{
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .upload-example {
    position: relative;
    display: inline-block;
    height: 96px;
    width: 96px;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    vertical-align: top;
  }
  
  .upload-example img {
    height: 78px;
    width: 78px;
  }
  
  .upload-example:before {
    position: absolute;
    bottom: 8px;
    left: 8px;
    content: ' ';
    width: 78px;
    height: 24px;
    background-color: #808080;
    opacity: .8;
  }
  
  .upload-example span {
    position: absolute;
    bottom: 8px;
    left: 8px;
    width: 78px;
    height: 24px;
    color: #fff;
    line-height: 24px;
    text-align: center;
  }
  .Btn{
      display: flex;
      flex-direction:flex-end
  }
  