.login {
  background-image: url('../../assets/images/bg15.jpg');
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.login-main {
  align-self: center;
}
.login-card {
  width: 400px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .1);
}
.login-tag {
  text-align: center;
  color: gray;
  margin-bottom: 0.4em;
  font-size: 12px;
}
.login-tag a {
  text-decoration: none;
  color: gray;
}
.login-logo {
  width: 60px;
  height: 60px;
  align-self: center;
  margin-left: 10px;
}
.logo-header {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 80px;
  background-color: rgba(77, 89, 90, 0.1);
}
.logo-title {
  font-size: 42px;
  font-weight: 600;
  color: rgb(78, 69, 69);
  margin-left: 15px;
  align-self: center;
}
