.student-resume-row {
  margin-bottom: 10px;
}
.ant-form-item-label label {
  width: 80px;
}
.detailTable .ant-table .ant-table-cell {
  padding-top: 7px;
  padding-bottom: 7px;
}
.studentResume-award > div {
  width: 85%;
  align-self: center;
}
.studentResume-award {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
