/*
 * @Author: LongJuanjuan
 * @Date: 2020-12-06 15:56:07
 * @Last Modified by: LongJuanjuan
 * @Last Modified time: 2021-03-03 11:16:38
 */
.printContainer{width:800px; margin:0 auto;}
.printContent{width:800px;}
.printContent h2{margin-bottom:40px;padding-bottom: 20px;font-weight: 600;color: #666666;}
.lookApply .tableRequire .requireContent{
  width: 100% !important;
}
.lookApply .ant-card{
  width: 100% !important;
  text-align: center;
}
.lookApply .ant-list-item{
  border-bottom: 0px solid #f0f0f0 !important;
  padding:0px;
}
.lookApply .ant-card-head{
  background-color: #E1DFE4;
  text-align: center;
  font-weight: 600 !important;
}
.noDataTip{
  height: 35px;
  line-height: 35px;
  text-align: center; font-size: 14px; font-weight: 600;}
.lookApply .ant-card-body{
  padding: 0px;
}
.ant-table-wrapper thead{
  background-color: #ffffff !important;
}
