/* .ant-modal-content{
  width: 200%;
  left: -40%;
}
.ant-modal-title{
  display: flex;
  justify-content: center;
  
} */
.myself .ant-modal-content{
  width: 200%;
  left: -40%;
}
/* .anbuxing .ant-modal-content{
  display: flex;
  justify-content: center;
}  */