.home-header {
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .1);
  margin-bottom: 8px;
}
.home-header-left {
  display: flex;
  flex-direction: row;
}
.title {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 30px;
  margin-left: 15px;
}
.title > a {
  text-decoration: none;
  color:  rgb(77, 89, 90);
  font-weight: 600;
}
.title > a:hover {
  color: rgb(77, 89, 90);
}
.home-dropmenu {
  display: flex;
  justify-content: center;
  margin-right: 20px;
}
.home-dropmenu > div {
  display: flex;
}
.home-dropmenu > div > button {
  align-self: center;
}
.menu {
  width: 250px;
  height: calc(100vh - 60px);
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .1);
}
.menu > ul {
  height: 100%;
}
.home {
  height: 100vh;
  overflow: hidden;
}
.home-content {
  display: flex;
  flex-direction: row;
}
.home-content-main {
  flex-grow: 1;
  height: calc(100vh - 60px);
  overflow: auto;
}
.home-logo {
  width: 50px;
  height: 50px;
  align-self: center;
  margin-left: 20px;
}
