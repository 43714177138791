/*
 * @Author: LongJuanjuan
 * @Date: 2020-12-06 15:55:46
 * @Last Modified by: HouQi
 * @Last Modified time: 2022-03-23 21:44:11
 */
 .sectionContainerh{
  width: 95%;
  margin: auto;
}
.headTitleh{
  text-align: center;
  padding-bottom: 15px;
  font-weight: 600 !important;
} 

.lookApplyBookh{
  width: 100%;
}
.lookApplyBookh .tableRequireh .requireContenth{
  width: 100% !important;
}
.lookApplyBookh .ant-cardh{
  width: 100% !important;
  text-align: center;
}
.lookApplyBookh .ant-list-itemh{
  border-bottom: 0px solid #f0f0f0 !important;
  padding:0px;
}
.lookApplyBookh .ant-card-headh{
  background-color: #E1DFE4 ;
  text-align: center;
  font-weight: 600 !important;
}

.lookApplyBookh .ant-card-bodyh{
  padding: 0px;
} 
.contentTableh{
  width: 100% !important;
}
.listItemh{
  padding: 0 !important;
}
.btnChangeh{
  margin-bottom: 10px; 
  height: 30px;
  /*  !important */
  display: flex;
  justify-content: flex-end;
}
.btnCenterh{
  margin: 10px; 
  height: 30px;
  display: flex !important;
  justify-content: center;
}
.sectionContainer{
  width: 95%;
  margin: auto;
}
.headTitle{
  text-align: center;
  padding-bottom: 15px;
  font-weight: 600 !important;
}
.table{
  margin-bottom: 20px;
}
.btn{
  clear: both;
  margin: 15px auto;
  text-align: center;
}
.tableRequire{
  width: 30%;
  float: left;
  margin-bottom: 30px;
}
.tableContent{
  float: left;
  width: 70%;
  margin-bottom: 30px;
}
.tableRequire .requireContent{
  width: 100% !important;
}
.requireContent .ant-card{
  width: 100% !important;
  text-align: center;
}
.tableRequire .ant-list-item{
  border-bottom: 0px solid #f0f0f0 !important;
  padding:0px;
}
.tableRequire .ant-card-head{
  background-color: #E1DFE4;
  text-align: center;
  font-weight: 600 !important;
}
.tableContent .content{
  width: 100% !important;
}
.tableContent .ant-card{
  width: 100% !important;
  text-align: center;
}
.tableContent .ant-list-item{
  border-bottom: 0px solid #f0f0f0 !important;
  padding:0px;
}
.tableContent .ant-card-head{
  background-color: #E1DFE4;
  text-align: center;
  font-weight: 600 !important;
}
.tableContent .ant-card-body{
  padding: 0px;
}
.listData{
  width: 100%;
}
.lookApplyBookModal{
  width: 70%;
  margin: 20px auto;
}
.lookApplyBookModal .tableRequire .requireContent{
  width: 100% !important;
}
.lookApplyBookModal .ant-card{
  width: 100% !important;
  text-align: center;
}
.lookApplyBookModal .ant-list-item{
  border-bottom: 0px solid #f0f0f0 !important;
  padding:0px;
}
.lookApplyBookModal .ant-card-head{
  background-color: #E1DFE4;
  text-align: center;
  font-weight: 600 !important;
}
.noData{
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}
.lookApplyBookModal .ant-card-body{
  padding: 0px;
}
