/*
 * @Author: BaiXin 
 * @Date: 2021-10-10 16:09:01 
 * @Last Modified by:   BaiXin 
 * @Last Modified time: 2021-10-10 16:09:01 
 */

.student-resume-row {
  margin-bottom: 10px;
}
.ant-form-item-label label {
  width: 80px;
}
