/*
 * @Author: LongJuanjuan
 * @Date: 2020-12-06 15:55:46
 * @Last Modified by: HouQi
 * @Last Modified time: 2022-03-25 14:49:45
 */
.sectionContainer{
  width: 95%;
  margin: auto;
}
.headTitle{
  text-align: center;
  padding-bottom: 15px;
  font-weight: 600 !important;
} 
.lookApplyBook{
  width: 100%;
}
.lookApplyBook .tableRequire .requireContent{
  width: 100% !important;
}
.lookApplyBook .ant-card{
  width: 100% !important;
  text-align: center;
}
.lookApplyBook .ant-list-item{
  border-bottom: 0px solid #f0f0f0 !important;
  padding:0px;
}
.lookApplyBook .ant-card-head{
  background-color: #E1DFE4 ;
  text-align: center;
  font-weight: 600 !important;
}

.lookApplyBook .ant-card-body{
  padding: 0px;
} 
.contentTable{
  width: 100% !important;
}
.listItem{
  padding: 0 !important;
}
.btnChange{
  margin-bottom: 10px; 
  height: 30px;
  display: flex !important;
  justify-content: flex-end;
}
.btnCenter{
  margin: 10px; 
  height: 30px;
  display: flex !important;
  justify-content: center;
}


/* 
.table{
  margin-bottom: 20px;
}
.btn{
  clear: both;
  margin: 15px auto;
  text-align: center;
}
.tableRequire{
  width: 30%;
  float: left;
  margin-bottom: 30px;
}
.tableContent{
  float: left;
  width: 70%;
  margin-bottom: 30px;
}
.tableRequire .requireContent{
  width: 100% !important;
}
.requireContent .ant-card{
  width: 100% !important;
  text-align: center;
}
.tableRequire .ant-list-item{
  border-bottom: 0px solid #f0f0f0 !important;
  padding:0px;
}
.tableRequire .ant-card-head{
  background-color: #E1DFE4;
  text-align: center;
  font-weight: 600 !important;
}
.tableContent .content{
  width: 100% !important;
}
.tableContent .ant-card{
  width: 100% !important;
  text-align: center;
}
.tableContent .ant-list-item{
  border-bottom: 0px solid #f0f0f0 !important;
  padding:0px;
}
.tableContent .ant-card-head{
  background-color: #E1DFE4;
  text-align: center;
  font-weight: 600 !important;
}
.tableContent .ant-card-body{
  padding: 0px;
}
.listData{
  width: 100%;
}
.noData{
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}
*/

/* .modalChange{
  width: 800px;
} */
