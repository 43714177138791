/*
 * @Author: LongJuanjuan
 * @Date: 2020-12-06 15:55:52
 * @Last Modified by: LongJuanjuan
 * @Last Modified time: 2020-12-06 15:56:15
 */
.uploadPicture{
  background-color: #5BC0DE !important;
  border-color: #5BC0DE !important;
  margin-right: 3px;
}
.alterMessage{
  margin-left: 3px;
  margin-right: 3px;
  background-color: #F0AD4E !important;
  border-color: #F0AD4E !important;
}
.delete{
  margin-left: 3px;
  margin-right: 3px;
}
.ant-upload span {
  font-size: 15px !important;
}
.operate-button{
  margin-left:3px;
  margin-right:3px;
}
