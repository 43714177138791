div.ant-col.ant-col-6 {
  height: 32px;
}
div.ant-col.ant-col-4 {
  height: 32px;
}
.light-row {
  
}
.dark-row {
  /* background-color: #FFFFFF; */
  background-color: #FCFCFC;
}